<template>
  <div>
    <div>
      <section
        class="loginformSection"
        style="
          background-image: url(assets/images/login-back.jpg);
          margin-top: 50px;
        "
      >
        <div class="row nomp">
          <div class="col-md-12 nomp">
            <div class="row nomp">
              <div class="col-md-6 nomp">
                <div>
                  <div class="loginWelcome">
                    <h1>Forgot Password</h1>

                    <h5>
                      You can sign in to access<br />
                      with your existing account
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-6 backwhite nomp">
                <div>
                  <div class="card card-container">
                    <!-- <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      /> -->
                    <div>
                      <div class="card-container" v-if="!item">
                        <form
                          name="form"
                          @submit.prevent="handleforgotpassword"
                        >
                          <div v-if="!successful">
                            <h5 class="b-b-head">Forgot Password</h5>

                            <div class="form-group">
                              <label for="email">Email</label>
                              <input
                                v-model="user.email"
                                v-validate="'email|max:50'"
                                type="email"
                                class="form-control"
                                name="email"
                                placeholder="Enter Email"
                              />
                              <div
                                v-if="submitted && errors.has('email')"
                                class="alert-danger"
                              >
                                {{ errors.first("email") }}
                              </div>
                            </div>

                            <div class="form-group">
                              <label
                                style="font-weight: 600; text-align: center"
                                >OR</label
                              >
                            </div>
                            <div class="form-group">
                              <label for="phone">Phone</label>
                              <VuePhoneNumberInput
                                v-model="yourValue"
                                :required="false"
                                :error="iserror"
                                color="red"
                                :noExample="true"
                                fetch-country
                                @update="resultsExample = $event"
                              />
                              <!-- <input
                                v-model="user.phone"
                                v-validate="'min:10|max:10'"
                                type="phone"
                                class="form-control"
                                name="phone"
                                placeholder="Enter phone number"
                              />
                              <div
                                v-if="submitted && errors.has('phone')"
                                class="alert-danger"
                              >
                                {{ errors.first("phone") }}
                              </div> -->
                            </div>
                            <div class="form-group">
                              <button
                                class="btn btn-primary btn-block sendBtn"
                                :disabled="loading"
                              >
                                <span
                                  v-show="loading"
                                  class="spinner-border spinner-border-sm"
                                ></span>
                                <span>Submit</span>
                              </button>
                            </div>
                          </div>
                        </form>
                        <!-- <div
                          v-if="message"
                          class="alert"
                          :class="successful ? 'alert-success' : 'alert-danger'"
                        >
                          {{ message }}
                        </div> -->
                      </div>
                      <div v-else-if="item">
                        <div class="card-container">
                          <form
                            name="form"
                            @submit.prevent="handleforgotpasswordotp"
                          >
                            <div v-if="!successful">
                              <h5 class="b-b-head">Enter OTP</h5>

                              <div class="form-group">
                                <label for="otp">OTP</label>
                                <input
                                  v-model="user.otp"
                                  v-validate="'required'"
                                  type="number"
                                  class="form-control"
                                  name="otp"
                                  placeholder="Enter OTP"
                                />
                                <div
                                  v-if="submitted && errors.has('otp')"
                                  class="alert-danger"
                                >
                                  {{ errors.first("otp") }}
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="password"> New Password</label>
                                <input
                                  v-model="user.Newpassword"
                                  v-validate="'required|min:6|max:40'"
                                  :type="passwordType"
                                  class="form-control"
                                  name="new password"
                                  ref="password"
                                  placeholder="Enter new password"
                                />
                                <div class="control passwordTypeCls">
                                  <div class="button" @click="toggleShow">
                                    <span class="icon is-small is-right">
                                      <i
                                        class="fas"
                                        :class="{
                                          'fa-eye-slash': showPassword,
                                          'fa-eye': !showPassword,
                                        }"
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  v-if="submitted && errors.has('new password')"
                                  class="alert-danger"
                                >
                                  {{ errors.first("new password") }}
                                </div>
                              </div>
                              <div class="form-group">
                                <label for="password">Re-enter Password</label>
                                <input
                                  v-model="user.reenterpassword"
                                  v-validate="
                                    'required|min:6|max:40|confirmed:password'
                                  "
                                  :type="passwordTypeNew"
                                  class="form-control"
                                  name="re-enter password"
                                  data-vv-as="password"
                                  placeholder="Confirm new password"
                                />
                                <div class="control passwordTypeCls">
                                  <div class="button" @click="toggleShowNew">
                                    <span class="icon is-small is-right">
                                      <i
                                        class="fas"
                                        :class="{
                                          'fa-eye-slash': showPasswordNew,
                                          'fa-eye': !showPasswordNew,
                                        }"
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  v-if="
                                    submitted && errors.has('re-enter password')
                                  "
                                  class="alert-danger"
                                >
                                  {{ errors.first("re-enter password") }}
                                </div>
                              </div>
                              <div class="form-group">
                                <button
                                  class="btn btn-primary btn-block sendBtn"
                                  :disabled="loading"
                                >
                                  <span
                                    v-show="loading"
                                    class="spinner-border spinner-border-sm"
                                  ></span>
                                  <span>Verify</span>
                                </button>
                              </div>
                            </div>
                          </form>
                          <!-- <div
                            v-if="message"
                            class="alert"
                            :class="
                              successful ? 'alert-success' : 'alert-danger'
                            "
                          >
                            {{ message }}
                          </div> -->
                        </div>
                      </div>
                    </div>
                    <!-- <div style="text-align: center">
                      <a href="/" >Go to home</a>
                    </div> -->
                    <div style="text-align: center">
                      <a href="/" class="go-to-h" rel="noopener noreferrer"
                        ><i class="fa fa-home" aria-hidden="true"></i> Go to
                        home</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import User from "../models/user";
import userService from "../services/user.service";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import config from "../config";
export default {
  name: "Login",
  data() {
    return {
      user: new User("", "", ""),
      submitted: false,
      successful: false,
      message: "",
      item: "",
      loading: false,
      passwordType: "password",
      passwordTypeNew: "password",
      showPassword: "",
      showPasswordNew: "",
      yourValue: "",
      resultsExample: null,
      iserror: false,
      phoneMessageError: "",
    };
  },
  components: {
    VuePhoneNumberInput,
  },

  updated() {
    if (this.resultsExample != null) {
      if (this.resultsExample.isValid == true && this.phoneMessageError != "") {
        this.phoneMessageError = "";
        // console.log(this.resultsExample.isValid);
      }
    }
  },
  methods: {
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }  if (variant == "success") {
        tittleMessage = "Success";
      } if (variant == "warning") {
        tittleMessage = "Warning";
      }

      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
    toggleShowNew() {
      this.showPasswordNew = !this.showPasswordNew;
      if (this.showPasswordNew) {
        this.passwordTypeNew = "text";
      } else {
        this.passwordTypeNew = "password";
      }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    handleforgotpassword() {
      // this.message = "";
      this.submitted = true;
      this.loading = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          if (this.resultsExample.isValid == true) {
            this.phoneMessageError = "";

            this.user.phone =
              "+" +
              this.resultsExample.countryCallingCode +
              "-" +
              this.resultsExample.nationalNumber;
          }
          if (this.user.phone || this.user.email) {
            if (this.user.phone) {
              // console.log(this.user.phone);
              this.userfield = this.user.phone;
            } else {
              // console.log(this.user.email);
              this.userfield = this.user.email;
            }
            userService.forgotpassword(this.userfield).then(
              (data) => {
                if (data.data.Message == "Success") {
                  this.item = data.data.Data[0];
                  this.loading = false;
                } else {
                  this.makeToast("danger", "! Oops somthings went wrong ");
                  this.loading = false;
                }
              },
              (error) => {
                let resMessage =
                  (error.response && error.response.data.Message) ||
                  error.message ||
                  error.toString();
                this.loading = false;
                this.successful = false;
                this.makeToast("danger", resMessage);
              }
            );
          } else {
            this.makeToast("danger", " Phone or Email Field is required");
            // this.message = " Phone or Email Field is required";
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      });
    },
    handleforgotpasswordotp() {
      this.message = "";
      this.submitted = true;
      this.loading = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          userService.forgotpasswordotp(this.user, this.item).then(
            (data) => {
              // this.message = data.data.Message;
              if (data.data.Message == "Success") {
                this.makeToast("success", "Password changed successfully");
                //this.message = "Password changed successfully";
              }
              this.successful = true;
              localStorage.setItem(
                "guestid",
                "_" + Math.random().toString(36).substr(2, 9)
              );
              setTimeout(
                () => this.$router.push({ path: "/" }),
                config.pageRedirectDelay * 1000
              );
            },
            (error) => {
              let resMessage =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
              this.successful = false;
              this.loading = false;
              this.makeToast("danger", resMessage);
            }
          );
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 380px !important;
  padding: 40px 40px;
}

.card {
  /* background-color: #f7f7f7; */
  padding: 20px 25px 30px;
  margin: 0 auto 100px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  /* -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
  border: none;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.sendBtn {
  background-image: linear-gradient(to right, #000e88, #9000a7) !important;
}
</style>
